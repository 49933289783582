import { logger } from '@getjust/browser-logger';
import NextErrorComponent, { ErrorProps } from 'next/error';

const Error = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
}: ErrorProps & { hasGetInitialPropsRun: boolean; err: any }) => {
  if (!hasGetInitialPropsRun && err) {
    logger.error('Error 404', { error: err });
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

export default Error;
